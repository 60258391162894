import { useDebugValue, useMemo } from 'react';
import Color from 'color';

function lighten(color, ratio) {
  if (!ratio) return color;
  if (!color) return undefined;

  if (typeof color === "function") {
    return function (palette) {
      return lighten(color(palette), ratio);
    };
  }

  var _color = Color(color);

  var lightness = _color.lightness();

  return _color.lightness(lightness + (100 - lightness) * ratio).hex().toString();
}
function useLighten(color, ratio) {
  useDebugValue(color + "*" + ratio);
  return useMemo(function () {
    return lighten(color, ratio);
  }, [color, ratio]);
}

export { lighten, useLighten };
