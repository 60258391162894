import { b as _objectWithoutPropertiesLoose, _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useRoleProps } from 'reakit-system-palette/Role';
import { useContrast } from 'reakit-system-palette/utils/contrast';
import { useFade } from 'reakit-system-palette/utils/fade';

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    [data-arrow] {\n      background-color: transparent;\n      & .stroke {\n        fill: ", ";\n      }\n      & .fill {\n        fill: ", ";\n      }\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function usePopoverOptions(_ref) {
  var _ref$unstable_system = _ref.unstable_system;
  _ref$unstable_system = _ref$unstable_system === void 0 ? {} : _ref$unstable_system;

  var _ref$unstable_system$ = _ref$unstable_system.palette,
      palette = _ref$unstable_system$ === void 0 ? "background" : _ref$unstable_system$,
      _ref$unstable_system$2 = _ref$unstable_system.fill,
      fill = _ref$unstable_system$2 === void 0 ? "opaque" : _ref$unstable_system$2,
      system = _objectWithoutPropertiesLoose(_ref$unstable_system, ["palette", "fill"]),
      options = _objectWithoutPropertiesLoose(_ref, ["unstable_system"]);

  return _objectSpread2({
    unstable_system: _objectSpread2({
      palette: palette,
      fill: fill
    }, system)
  }, options);
}
function usePopoverProps(_ref2, htmlProps) {
  var unstable_system = _ref2.unstable_system;

  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var _usePaletteRoleProps = useRoleProps({
    unstable_system: unstable_system
  }),
      backgroundColor = _usePaletteRoleProps.style.backgroundColor;

  var foreground = useContrast(backgroundColor) || "black";
  var borderColor = useFade(foreground, 0.75);
  var popover = css(_templateObject(), borderColor, backgroundColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(popover, htmlProps.className)
  });
}
function usePopoverArrowProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  return _objectSpread2({
    "data-arrow": ""
  }, htmlProps);
}

export { usePopoverArrowProps, usePopoverOptions, usePopoverProps };
