import { useDebugValue, useMemo } from 'react';
import Color from 'color';

function isLight(color) {
  return Color(color).isLight();
}
function useIsLight(color) {
  useDebugValue(color);
  return useMemo(function () {
    return isLight(color);
  }, [color]);
}

export { isLight, useIsLight };
