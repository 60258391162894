import { useDebugValue, useMemo } from 'react';
import Color from 'color';

function fade(color, ratio) {
  if (!ratio) return color;
  if (!color) return undefined;

  if (typeof color === "function") {
    return function (palette) {
      return fade(color(palette), ratio);
    };
  }

  return Color(color).fade(ratio).rgb().toString();
}
function useFade(color, ratio) {
  useDebugValue(color + "*" + ratio);
  return useMemo(function () {
    return fade(color, ratio);
  }, [color, ratio]);
}

export { fade, useFade };
