import { _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useRoleProps } from 'reakit-system-palette/Role';
import { useContrast } from 'reakit-system-palette/utils/contrast';
import { useFade } from 'reakit-system-palette/utils/fade';
import { usePalette } from 'reakit-system-palette/utils/palette';
import { useLighten } from 'reakit-system-palette/utils/lighten';

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    display: block;\n    width: 100%;\n    border-radius: 0.2rem;\n    padding: 0.5em 0.75em;\n    font-size: 100%;\n    border: 1px solid ", ";\n    color: ", ";\n    margin: 0 !important;\n\n    &:focus {\n      border-color: ", ";\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useInputProps(_ref, htmlProps) {
  var unstable_system = _ref.unstable_system;

  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var _usePaletteRoleProps = useRoleProps({
    unstable_system: unstable_system
  }),
      _usePaletteRoleProps$ = _usePaletteRoleProps.style,
      backgroundColor = _usePaletteRoleProps$.backgroundColor,
      originalBorderColor = _usePaletteRoleProps$.borderColor;

  var foreground = useContrast(backgroundColor) || "black";
  var color = useLighten(foreground, 0.3);
  var primary = usePalette("primary");
  var borderColor = useFade(foreground, 0.75);
  var focusBorderColor = useLighten(primary, 0.4);
  var formInput = css(_templateObject(), originalBorderColor || borderColor, color, originalBorderColor || focusBorderColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(formInput, htmlProps.className)
  });
}

export { useInputProps };
