import { useDebugValue, useMemo } from 'react';
import { useToken } from 'reakit-system/useToken';
import Color from 'color';
import { isLight } from './utils/isLight.js';

function contrastRatio(color1, color2) {
  return Color(color1).contrast(Color(color2));
}
function useContrastRatio(color1, color2) {
  useDebugValue(color1 + " - " + color2);
  return useMemo(function () {
    return contrastRatio(color1, color2);
  }, [color1, color2]);
}
function contrast(color) {
  if (!color) return function () {
    return undefined;
  };

  if (typeof color === "function") {
    return function (palette) {
      return contrast(color(palette))(palette);
    };
  }

  var colorIsLight = isLight(color);
  return function (palette) {
    if (colorIsLight) return getPalette(palette, "black") || "black";
    return getPalette(palette, "white") || "white";
  };
}
function useContrast(color) {
  useDebugValue(color);
  var palette = useToken("palette");
  return useMemo(function () {
    return contrast(color)(palette);
  }, [color, palette]);
}

var palette = {
  white: "#ffffff",
  black: "#212121",
  light: "#f8f9fa",
  dark: "#343a40",
  primary: "#006DFF",
  secondary: "#6C757D",
  success: "#28A745",
  info: "#17A2B8",
  warning: "#FFC107",
  danger: "#DC3545",
  background: ref("white", "#ffffff"),
  foreground: contrast(ref("background", "#ffffff"))
};

function getPalette(palette$1, name) {
  if (palette$1 === void 0) {
    palette$1 = palette;
  }

  if (!name) return undefined;
  var color = palette$1[name];

  while (typeof color === "function") {
    color = color(palette$1);
  }

  return color;
}
function usePalette(name) {
  useDebugValue(name);
  var palette = useToken("palette");
  return getPalette(palette, name);
}

function ref(name, fallback) {
  return function (palette) {
    return getPalette(palette, name) || fallback;
  };
}

export { contrast as a, useContrast as b, contrastRatio as c, usePalette as d, getPalette as g, palette as p, ref as r, useContrastRatio as u };
