import { b as _objectWithoutPropertiesLoose, _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useRoleProps } from 'reakit-system-palette/Role';
import { useDarken } from 'reakit-system-palette/utils/darken';
import { useContrast } from 'reakit-system-palette/utils/contrast';

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    display: inline-flex;\n    font-weight: 400;\n    align-items: center;\n    justify-content: center;\n    user-select: none;\n    padding: 0.375em 0.75em;\n    line-height: 1.5;\n    border-radius: 0.25rem;\n    text-decoration: none;\n    border: 1px solid ", ";\n    cursor: pointer;\n    white-space: nowrap;\n    color: ", ";\n    background-color: ", ";\n    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,\n      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n    font-size: 100%;\n\n    &[aria-disabled=\"true\"] {\n      cursor: auto;\n    }\n\n    &:not([aria-disabled=\"true\"]) {\n      &:hover {\n        color: ", ";\n        border-color: ", ";\n        background-color: ", ";\n      }\n      &:active,\n      &[data-active],\n      &[aria-expanded=\"true\"] {\n        color: ", ";\n        border-color: ", ";\n        background-color: ", ";\n      }\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useButtonOptions(_ref) {
  var _ref$unstable_system = _ref.unstable_system;
  _ref$unstable_system = _ref$unstable_system === void 0 ? {} : _ref$unstable_system;

  var _ref$unstable_system$ = _ref$unstable_system.fill,
      fill = _ref$unstable_system$ === void 0 ? "opaque" : _ref$unstable_system$,
      _ref$unstable_system$2 = _ref$unstable_system.palette,
      palette = _ref$unstable_system$2 === void 0 ? "primary" : _ref$unstable_system$2,
      system = _objectWithoutPropertiesLoose(_ref$unstable_system, ["fill", "palette"]),
      options = _objectWithoutPropertiesLoose(_ref, ["unstable_system"]);

  return _objectSpread2({
    unstable_system: _objectSpread2({
      fill: fill,
      palette: palette
    }, system)
  }, options);
}
function useButtonProps(_ref2, htmlProps) {
  var unstable_system = _ref2.unstable_system;

  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var _usePaletteRoleProps = useRoleProps({
    unstable_system: unstable_system
  }),
      _usePaletteRoleProps$ = _usePaletteRoleProps.style,
      color = _usePaletteRoleProps$.color,
      backgroundColor = _usePaletteRoleProps$.backgroundColor,
      _usePaletteRoleProps$2 = _usePaletteRoleProps$.borderColor,
      borderColor = _usePaletteRoleProps$2 === void 0 ? "transparent" : _usePaletteRoleProps$2;

  var hoverBackgroundColor = useDarken(backgroundColor || color, unstable_system.fill !== "opaque" ? 0 : 0.1);
  var activeBackgroundColor = useDarken(hoverBackgroundColor, 0.1);
  var hoverBorderColor = useDarken(backgroundColor || color, 0.2);
  var activeBorderColor = useDarken(hoverBorderColor, 0.1);
  var hoverColor = useContrast(hoverBackgroundColor);
  var activeColor = useContrast(activeBackgroundColor);
  var button = css(_templateObject(), borderColor, color, backgroundColor || "transparent", hoverColor, hoverBorderColor, hoverBackgroundColor, activeColor, activeBorderColor, activeBackgroundColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(button, htmlProps.className)
  });
}

export { useButtonOptions, useButtonProps };
