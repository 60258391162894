import { useDebugValue, useMemo } from 'react';
import Color from 'color';

function darken(color, ratio) {
  if (!ratio) return color;
  if (!color) return undefined;

  if (typeof color === "function") {
    return function (palette) {
      return darken(color(palette), ratio);
    };
  }

  var _color = Color(color);

  var lightness = _color.lightness();

  return _color.lightness(lightness - lightness * ratio).hex().toString();
}
function useDarken(color, ratio) {
  useDebugValue(color + "*" + ratio);
  return useMemo(function () {
    return darken(color, ratio);
  }, [color, ratio]);
}

export { darken, useDarken };
