import { _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useRoleProps as useRoleProps$1 } from 'reakit-system-palette/Role';

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    box-sizing: border-box;\n    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n    font-family: -apple-system, system-ui, BlinkMacSystemFont, \"Segoe UI\",\n      \"Helvetica Neue\", Helvetica, Arial, sans-serif, \"Apple Color Emoji\",\n      \"Segoe UI Emoji\", \"Segoe UI Symbol\";\n\n    ", "\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useRoleProps(_ref, htmlProps) {
  var unstable_system = _ref.unstable_system;

  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var _usePaletteRoleProps = useRoleProps$1({
    unstable_system: unstable_system
  }),
      style = _usePaletteRoleProps.style;

  var role = css(_templateObject(), style);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(role, htmlProps.className)
  });
}

export { useRoleProps };
