import { b as _objectWithoutPropertiesLoose, _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useRoleProps } from 'reakit-system-palette/Role';
import { useContrast } from 'reakit-system-palette/utils/contrast';
import { useFade } from 'reakit-system-palette/utils/fade';
import { usePalette } from 'reakit-system-palette/utils/palette';

function _templateObject2() {
  var data = _taggedTemplateLiteralLoose(["\n    background-color: rgba(0, 0, 0, 0.5);\n    position: fixed;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    z-index: 999;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    position: fixed;\n    top: 28px;\n    left: 50%;\n    transform: translateX(-50%);\n    border-radius: 0.25rem;\n    padding: 1em;\n    max-height: calc(100vh - 56px);\n    outline: 0;\n    border: 1px solid ", ";\n    color: ", ";\n    z-index: 999;\n\n    &:focus {\n      box-shadow: 0 0 0 0.2em ", ";\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useDialogOptions(_ref) {
  var _ref$unstable_system = _ref.unstable_system;
  _ref$unstable_system = _ref$unstable_system === void 0 ? {} : _ref$unstable_system;

  var _ref$unstable_system$ = _ref$unstable_system.palette,
      palette = _ref$unstable_system$ === void 0 ? "background" : _ref$unstable_system$,
      _ref$unstable_system$2 = _ref$unstable_system.fill,
      fill = _ref$unstable_system$2 === void 0 ? "opaque" : _ref$unstable_system$2,
      system = _objectWithoutPropertiesLoose(_ref$unstable_system, ["palette", "fill"]),
      options = _objectWithoutPropertiesLoose(_ref, ["unstable_system"]);

  return _objectSpread2(_objectSpread2({}, options), {}, {
    unstable_system: _objectSpread2({
      palette: palette,
      fill: fill
    }, system)
  });
}
function useDialogProps(_ref2, htmlProps) {
  var unstable_system = _ref2.unstable_system;

  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var _usePaletteRoleProps = useRoleProps({
    unstable_system: unstable_system
  }),
      _usePaletteRoleProps$ = _usePaletteRoleProps.style,
      color = _usePaletteRoleProps$.color,
      backgroundColor = _usePaletteRoleProps$.backgroundColor;

  var foreground = useContrast(backgroundColor) || "black";
  var primaryColor = usePalette("primary");
  var borderColor = useFade(foreground, 0.75);
  var boxShadowColor = useFade(primaryColor || color || foreground, 0.5);
  var dialog = css(_templateObject(), borderColor, color, boxShadowColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(dialog, htmlProps.className)
  });
}
function useDialogBackdropProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var dialogBackdrop = css(_templateObject2());
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(dialogBackdrop, htmlProps.className)
  });
}

export { useDialogBackdropProps, useDialogOptions, useDialogProps };
