import { _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useFade } from 'reakit-system-palette/utils/fade';
import { usePalette } from 'reakit-system-palette/utils/palette';

function _templateObject2() {
  var data = _taggedTemplateLiteralLoose(["\n    display: flex;\n    flex-direction: row;\n    border: 1px solid ", ";\n    border-width: 0 0 1px 0;\n    margin: 0 0 1em 0;\n\n    &[aria-orientation=\"vertical\"] {\n      flex-direction: column;\n      border-width: 0 1px 0 0;\n      margin: 0 1em 0 0;\n    }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    background-color: transparent;\n    border: 1px solid transparent;\n    border-width: 1px 1px 0 1px;\n    border-radius: 0.25rem 0.25rem 0 0;\n    font-size: 100%;\n    padding: 0.5em 1em;\n    margin: 0 0 -1px 0;\n\n    &[aria-selected=\"true\"] {\n      background-color: ", ";\n      border-color: ", ";\n    }\n\n    [aria-orientation=\"vertical\"] & {\n      border-width: 1px 0 1px 1px;\n      border-radius: 0.2em 0 0 0.2em;\n      margin: 0 -1px 0 0;\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useTabProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var background = usePalette("background") || "white";
  var foreground = usePalette("foreground") || "black";
  var borderColor = useFade(foreground, 0.75);
  var tab = css(_templateObject(), background, borderColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(tab, htmlProps.className)
  });
}
function useTabListProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var foreground = usePalette("foreground") || "black";
  var borderColor = useFade(foreground, 0.75);
  var tabList = css(_templateObject2(), borderColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(tabList, htmlProps.className)
  });
}

export { useTabListProps, useTabProps };
