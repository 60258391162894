import { _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    display: flex;\n    flex-direction: row;\n\n    & > *:not(:first-child) {\n      margin: 0 0 0 0.5em;\n    }\n\n    &[aria-orientation=\"vertical\"] {\n      display: inline-flex;\n      flex-direction: column;\n\n      & > *:not(:first-child) {\n        margin: 0.5em 0 0;\n      }\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useToolbarProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var toolbar = css(_templateObject());
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(toolbar, htmlProps.className)
  });
}

export { useToolbarProps };
