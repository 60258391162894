import { _ as _objectSpread2, b as _objectWithoutPropertiesLoose, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useRoleProps } from 'reakit-system-palette/Role';
import { useContrast } from 'reakit-system-palette/utils/contrast';
import { useFade } from 'reakit-system-palette/utils/fade';
import { unstable_getIn } from 'reakit/Form/utils/getIn';
import { useLighten } from 'reakit-system-palette/utils/lighten';

function _templateObject4() {
  var data = _taggedTemplateLiteralLoose(["\n    display: block;\n    color: ", ";\n    border: 1px solid ", ";\n    border-radius: 0.25rem;\n    padding: 0.5rem 1rem 1rem;\n    & > * {\n      display: block;\n    }\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteralLoose(["\n    display: block;\n    margin: 0 0 0.5rem 0 !important;\n\n    input[type=\"checkbox\"] + &,\n    input[type=\"radio\"] + & {\n      display: inline-block;\n      margin: 0 0 0 0.5rem !important;\n    }\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteralLoose(["\n    font-size: 0.8em;\n    margin-top: 0.5rem !important;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    > *:not(:first-child) {\n      margin-top: 1rem;\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useFormProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var form = css(_templateObject());
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(form, htmlProps.className)
  });
}
function useFormInputOptions(_ref) {
  var _ref$unstable_system = _ref.unstable_system;
  _ref$unstable_system = _ref$unstable_system === void 0 ? {} : _ref$unstable_system;

  var _ref$unstable_system$ = _ref$unstable_system.fill,
      fill = _ref$unstable_system$ === void 0 ? "outline" : _ref$unstable_system$,
      system = _objectWithoutPropertiesLoose(_ref$unstable_system, ["fill"]),
      options = _objectWithoutPropertiesLoose(_ref, ["unstable_system"]);

  var isInvalid = Boolean(unstable_getIn(options.touched, options.name) && unstable_getIn(options.errors, options.name));
  return _objectSpread2({
    unstable_system: _objectSpread2(_objectSpread2({
      fill: fill
    }, system), {}, {
      palette: isInvalid ? "danger" : system.palette
    })
  }, options);
}
function useFormMessageOptions(_ref2) {
  var _ref2$unstable_system = _ref2.unstable_system,
      system = _ref2$unstable_system === void 0 ? {} : _ref2$unstable_system,
      options = _objectWithoutPropertiesLoose(_ref2, ["unstable_system"]);

  var isInvalid = Boolean(unstable_getIn(options.errors, options.name));
  return _objectSpread2({
    unstable_system: _objectSpread2(_objectSpread2({}, system), {}, {
      palette: isInvalid ? "danger" : system.palette || "success"
    })
  }, options);
}
function useFormMessageProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var formMessage = css(_templateObject2());
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(formMessage, htmlProps.className)
  });
}
function useFormLabelProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var formLabel = css(_templateObject3());
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(formLabel, htmlProps.className)
  });
}
function useFormGroupOptions(_ref3) {
  var _ref3$unstable_system = _ref3.unstable_system;
  _ref3$unstable_system = _ref3$unstable_system === void 0 ? {} : _ref3$unstable_system;

  var _ref3$unstable_system2 = _ref3$unstable_system.fill,
      fill = _ref3$unstable_system2 === void 0 ? "outline" : _ref3$unstable_system2,
      system = _objectWithoutPropertiesLoose(_ref3$unstable_system, ["fill"]),
      options = _objectWithoutPropertiesLoose(_ref3, ["unstable_system"]);

  var isInvalid = Boolean(unstable_getIn(options.touched, options.name) && unstable_getIn(options.errors, options.name));
  return _objectSpread2({
    unstable_system: _objectSpread2(_objectSpread2({
      fill: fill
    }, system), {}, {
      palette: isInvalid ? "danger" : system.palette
    })
  }, options);
}
function useFormGroupProps(_ref4, htmlProps) {
  var unstable_system = _ref4.unstable_system;

  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var _usePaletteRoleProps = useRoleProps({
    unstable_system: unstable_system
  }),
      _usePaletteRoleProps$ = _usePaletteRoleProps.style,
      backgroundColor = _usePaletteRoleProps$.backgroundColor,
      originalBorderColor = _usePaletteRoleProps$.borderColor;

  var foreground = useContrast(backgroundColor) || "black";
  var color = useLighten(foreground, 0.3);
  var borderColor = useFade(foreground, 0.75);
  var formGroup = css(_templateObject4(), color, originalBorderColor || borderColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(formGroup, htmlProps.className)
  });
}
function useFormRemoveButtonOptions(_ref5) {
  var _ref5$unstable_system = _ref5.unstable_system;
  _ref5$unstable_system = _ref5$unstable_system === void 0 ? {} : _ref5$unstable_system;

  var _ref5$unstable_system2 = _ref5$unstable_system.palette,
      palette = _ref5$unstable_system2 === void 0 ? "danger" : _ref5$unstable_system2,
      system = _objectWithoutPropertiesLoose(_ref5$unstable_system, ["palette"]),
      options = _objectWithoutPropertiesLoose(_ref5, ["unstable_system"]);

  return _objectSpread2({
    unstable_system: _objectSpread2({
      palette: palette
    }, system)
  }, options);
}

export { useFormGroupOptions, useFormGroupProps, useFormInputOptions, useFormLabelProps, useFormMessageOptions, useFormMessageProps, useFormProps, useFormRemoveButtonOptions };
