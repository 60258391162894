import { b as _objectWithoutPropertiesLoose, _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useRoleProps } from 'reakit-system-palette/Role';

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    align-items: center;\n    justify-content: center;\n    padding: 0.375em 0.75em;\n    font-size: 100%;\n    border: 0;\n    color: inherit;\n    background-color: inherit;\n\n    &:not([aria-selected=\"true\"]) {\n      color: inherit;\n      background-color: inherit;\n    }\n\n    [aria-activedescendant=\"", "\"]:focus &[aria-selected=\"true\"],\n    [aria-activedescendant=\"", "\"]:focus ~ * &[aria-selected=\"true\"] {\n      color: ", ";\n      background-color ", ";\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useCompositeItemOptions(_ref) {
  var _ref$unstable_system = _ref.unstable_system;
  _ref$unstable_system = _ref$unstable_system === void 0 ? {} : _ref$unstable_system;

  var _ref$unstable_system$ = _ref$unstable_system.fill,
      fill = _ref$unstable_system$ === void 0 ? "opaque" : _ref$unstable_system$,
      _ref$unstable_system$2 = _ref$unstable_system.palette,
      palette = _ref$unstable_system$2 === void 0 ? "primary" : _ref$unstable_system$2,
      system = _objectWithoutPropertiesLoose(_ref$unstable_system, ["fill", "palette"]),
      options = _objectWithoutPropertiesLoose(_ref, ["unstable_system"]);

  return _objectSpread2({
    unstable_system: _objectSpread2({
      fill: fill,
      palette: palette
    }, system)
  }, options);
}
function useCompositeItemProps(_ref2, htmlProps) {
  var unstable_system = _ref2.unstable_system,
      id = _ref2.id;

  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var _usePaletteRoleProps = useRoleProps({
    unstable_system: unstable_system
  }),
      _usePaletteRoleProps$ = _usePaletteRoleProps.style,
      color = _usePaletteRoleProps$.color,
      backgroundColor = _usePaletteRoleProps$.backgroundColor;

  var compositeItem = css(_templateObject(), id, id, color, backgroundColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(compositeItem, htmlProps.className)
  });
}

export { useCompositeItemOptions, useCompositeItemProps };
