import { b as _objectWithoutPropertiesLoose, _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useRoleProps } from 'reakit-system-palette/Role';
import { useDarken } from 'reakit-system-palette/utils/darken';
import { useContrastRatio } from 'reakit-system-palette/utils/contrast';
import { useFade } from 'reakit-system-palette/utils/fade';
import { usePalette } from 'reakit-system-palette/utils/palette';
import { useLighten } from 'reakit-system-palette/utils/lighten';
import { useIsLight } from 'reakit-system-palette/utils/isLight';

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    &:not([type=\"checkbox\"]):not([type=\"radio\"]) {\n      transition: box-shadow 0.15s ease-in-out;\n      outline: 0;\n\n      &:focus {\n        box-shadow: 0 0 0 0.2em ", ";\n        position: relative;\n        z-index: 2;\n      }\n\n      &:hover {\n        z-index: 2;\n      }\n    }\n\n    &[aria-disabled=\"true\"] {\n      opacity: 0.5;\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useTabbableProps(_ref, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var _ref$unstable_system = _ref.unstable_system;
  _ref$unstable_system = _ref$unstable_system === void 0 ? {} : _ref$unstable_system;

  var _ref$unstable_system$ = _ref$unstable_system.palette,
      palette = _ref$unstable_system$ === void 0 ? "primary" : _ref$unstable_system$,
      system = _objectWithoutPropertiesLoose(_ref$unstable_system, ["palette"]);

  var _usePaletteRoleProps = useRoleProps({
    unstable_system: _objectSpread2({
      palette: palette
    }, system)
  }),
      _usePaletteRoleProps$ = _usePaletteRoleProps.style,
      color = _usePaletteRoleProps$.color,
      backgroundColor = _usePaletteRoleProps$.backgroundColor;

  var dark = usePalette("dark") || "black";
  var background = usePalette("background") || "white";
  var backgroundIsLight = useIsLight(background);
  var strokeColor = backgroundColor || color || dark;
  var contrastRatio = useContrastRatio(background, strokeColor);
  var darker = useDarken(strokeColor, contrastRatio < 1.2 ? 0.25 : 0);
  var lighter = useLighten(strokeColor, contrastRatio < 1.2 ? 0.25 : 0);
  var boxShadowColor = useFade(backgroundIsLight ? darker : lighter, 0.6);
  var tabbable = css(_templateObject(), boxShadowColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(tabbable, htmlProps.className)
  });
}

export { useTabbableProps };
