import 'react';
import 'reakit-system/useToken';
import 'color';
import './utils/isLight.js';
import { d as usePalette, b as useContrast } from './ref-bbcfbae4.js';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function useRoleProps(_ref2, _ref) {
  var _ref2$unstable_system = _ref2.unstable_system,
      system = _ref2$unstable_system === void 0 ? {} : _ref2$unstable_system;

  if (_ref === void 0) {
    _ref = {};
  }

  var _ref3 = _ref,
      htmlStyle = _ref3.style,
      htmlProps = _objectWithoutPropertiesLoose(_ref3, ["style"]);

  var color = usePalette(system.palette);
  var contrast = useContrast(color);
  var textColor = system.fill === "opaque" ? contrast : color;
  var backgroundColor = system.fill === "opaque" ? color : undefined;
  var borderColor = system.fill === "outline" ? color : undefined;
  var style = {};

  if (textColor) {
    style.color = textColor;
  }

  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }

  if (borderColor) {
    style.border = "1px solid " + borderColor;
    style.borderColor = borderColor;
  }

  return _objectSpread2({
    style: _objectSpread2(_objectSpread2({}, style), htmlStyle)
  }, htmlProps);
}

export { useRoleProps };
