import { _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    display: flex;\n\n    & > :not(:first-child) {\n      margin-left: -1px;\n    }\n\n    & > :not(:first-child):not(:last-child):not(.first-child):not(.last-child) {\n      border-radius: 0;\n    }\n\n    & > :first-child:not(:last-child),\n    & > .first-child {\n      border-top-right-radius: 0;\n      border-bottom-right-radius: 0;\n    }\n\n    & > :last-child:not(:first-child),\n    & > .last-child {\n      border-top-left-radius: 0;\n      border-bottom-left-radius: 0;\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useGroupProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var group = css(_templateObject());
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(group, htmlProps.className)
  });
}

export { useGroupProps };
