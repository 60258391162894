import * as React from "react";

export type LogoProps = React.SVGAttributes<SVGElement> & {
  colored?: boolean;
};

export default function Logo({ colored, ...props }: LogoProps) {
  return (
    <svg viewBox="0 0 540.3 121.3" height={20} {...props}>
      <defs>
        <linearGradient
          id="Default_gradient"
          y1="89.43"
          x2="119.65"
          y2="89.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d060ff" />
          <stop offset="0.7" stopColor="#7b60ff" />
          <stop offset="1" stopColor="#7860ff" />
        </linearGradient>
      </defs>
      <g fill={colored ? "#6a50ee" : "currentColor"}>
        <path d="M206.6,72.9l33.7,46.4H220.2L189.1,74.7h-3v44.6H169.6V10.3h19.3q21.6,0,31.2,8.1c7.1,6.1,10.6,14,10.6,23.9a31,31,0,0,1-6.6,19.9A29.7,29.7,0,0,1,206.6,72.9ZM186.1,60.4h5.2c15.6,0,23.4-6,23.4-17.9S207.1,25.8,192,25.8h-5.9Z" />
        <path d="M311.1,88.7H261.8c.4,5.6,2.2,10.1,5.5,13.5s7.4,4.9,12.5,4.9a16.2,16.2,0,0,0,9.8-2.8c2.5-1.9,5.4-5.4,8.7-10.4l13.4,7.4a52.2,52.2,0,0,1-6.6,9.1,33,33,0,0,1-7.4,6.2,32,32,0,0,1-8.5,3.6,45.1,45.1,0,0,1-10,1.1c-10.2,0-18.5-3.3-24.7-9.9S245.2,96,245.2,85s3-19.7,9-26.4,14.2-9.9,24.2-9.9,18.1,3.2,24,9.6,8.7,15.3,8.7,26.7Zm-16.3-13c-2.3-8.5-7.6-12.7-16.1-12.7a16.1,16.1,0,0,0-5.4.9,15.8,15.8,0,0,0-4.6,2.5,17.2,17.2,0,0,0-3.6,4,21.2,21.2,0,0,0-2.3,5.3Z" />
        <path d="M376.2,50.6h16v68.7h-16v-7.2c-6.5,6.2-13.5,9.2-21.1,9.2-9.5,0-17.3-3.4-23.5-10.3s-9.3-15.8-9.3-26.3,3.1-18.9,9.3-25.7a29.5,29.5,0,0,1,23.1-10.3c8,0,15.2,3.3,21.5,9.8ZM338.5,84.7c0,6.6,1.8,12,5.3,16.1a17.4,17.4,0,0,0,13.7,6.3c5.9,0,10.7-2,14.3-6s5.4-9.6,5.4-16-1.8-11.8-5.4-16A17.8,17.8,0,0,0,357.7,63,17.5,17.5,0,0,0,344,69.2,23.1,23.1,0,0,0,338.5,84.7Z" />
        <path d="M425.2.1V72.8l22.4-22.2H469L439.1,79.4l32,39.9H450.5l-22.7-29-2.6,2.6v26.4H409.4V.1Z" />
        <path d="M476.1,22a9.8,9.8,0,0,1,3-7.2,9.8,9.8,0,0,1,7.3-3.1,9.6,9.6,0,0,1,7.3,3.1,9.4,9.4,0,0,1,3.1,7.2,9.8,9.8,0,0,1-3.1,7.4,9.7,9.7,0,0,1-7.2,3A10.2,10.2,0,0,1,476.1,22Zm18.3,28.6v68.7H478.5V50.6Z" />
        <path d="M528,65.4v53.9H512.1V65.4h-6.8V50.6h6.8V25.4H528V50.6h12.3V65.4Z" />
      </g>
      <g fill={colored ? "url(#Default_gradient)" : "currentColor"}>
        <polygon points="0 59.5 59.8 119.3 119.7 119.3 60.3 59.5 0 59.5" />
        <path d="M90.8,59.5h0A29.4,29.4,0,0,0,90.8.7H0L60.3,59.5Z" />
      </g>
    </svg>
  );
}
