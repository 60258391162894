import { _ as _objectSpread2, a as _taggedTemplateLiteralLoose } from './_rollupPluginBabelHelpers-ac7c98be.js';
import { css, cx } from 'emotion';
import { useFade } from 'reakit-system-palette/utils/fade';
import { usePalette } from 'reakit-system-palette/utils/palette';

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n    border: 1px solid ", ";\n    border-width: 0 1px 0 0;\n    margin: 0 0.5em;\n    padding: 0;\n    width: 0;\n    height: auto;\n\n    &[aria-orientation=\"horizontal\"] {\n      border-width: 0 0 1px 0;\n      margin: 0.5em 0;\n      width: auto;\n      height: 0;\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
function useSeparatorProps(_, htmlProps) {
  if (htmlProps === void 0) {
    htmlProps = {};
  }

  var foreground = usePalette("foreground") || "black";
  var borderColor = useFade(foreground, 0.75);
  var separator = css(_templateObject(), borderColor);
  return _objectSpread2(_objectSpread2({}, htmlProps), {}, {
    className: cx(separator, htmlProps.className)
  });
}

export { useSeparatorProps };
